import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useCurrentUserIguanaObject, useNavigationHelper } from 'FrontRoyalAngular';
import { useAppHeaderViewModel } from 'Navigation';
import { useTranslation } from 'react-i18next';
import { getProgramConfigValue, ProgramField, type ProgramType, ProgramTypeConfigs } from 'Program';
import { getCurrentlyPreventedFromSubmittingApplication } from 'Users';
import { InstitutionId } from '../../Institutions.types';
import { chooseInstitutionConfig } from '../chooseInstitutionConfig';
import { InstitutionItem } from './InstitutionItem';

type ChooseInstitutionPagePresentationProps = {
    config: Awaited<ReturnType<typeof chooseInstitutionConfig>>;
    onApplyClick: (programType: ProgramType, institutionId: InstitutionId) => void;
    loadingInstitutionId: InstitutionId | null;
};

const classes = {
    outerContainer: clsx(['h-full', 'w-full', 'sm:min-h-[calc(100vh-75px)]']),
    presentationContainer: clsx([
        'flex',
        'h-full',
        'w-full',
        'animate-fade-in',
        'flex-col',
        'items-center',
        'px-[15px]',
        'pb-[15px]',
        'pt-[30px]',
        'sm:pt-[70px]',
    ]),
    pageHeading: clsx(['mb-[20px]', 'text-center', 'text-[25px]', 'font-semibold', 'sm:mb-[48px]']),
    institutionsContainer: clsx(['flex', 'flex-wrap', 'justify-center', 'gap-[20px]', 'sm:gap-[30px]']),
};

export function ChooseInstitutionPage() {
    const currentUser = useCurrentUserIguanaObject();
    const AppHeaderViewModel = useAppHeaderViewModel();
    const { loadRoute } = useNavigationHelper();
    const [loadingInstitutionId, setLoadingInstitutionId] = useState<InstitutionId | null>(null);
    const [config, setConfig] = useState<Awaited<ReturnType<typeof chooseInstitutionConfig>> | null>(null);

    AppHeaderViewModel.setBodyBackground('beige');

    const handleApplyClick = useCallback(
        async (programType: ProgramType, institutionId: InstitutionId) => {
            setLoadingInstitutionId(institutionId);
            await currentUser!.ensureAndChangePrefProgram(programType);
            setLoadingInstitutionId(null);
            loadRoute('/settings/application');
        },
        [currentUser, setLoadingInstitutionId, loadRoute],
    );

    useEffect(() => {
        const getConfig = async () => {
            setConfig(await chooseInstitutionConfig());
        };
        getConfig();
    }, [setConfig]);

    // Redirect to dashboard if user already has a pending business admin program application
    useEffect(() => {
        if (!currentUser) return;

        const mbaFieldProgramTypes = Object.keys(ProgramTypeConfigs).filter(
            programType =>
                getProgramConfigValue(programType as ProgramType, 'programField') ===
                ProgramField.master_of_business_administration,
        ) as ProgramType[];

        const currentlyPrevented = mbaFieldProgramTypes.some(programType =>
            getCurrentlyPreventedFromSubmittingApplication(currentUser, { programType }),
        );

        if (!currentlyPrevented) return;

        loadRoute('/dashboard');
    }, [currentUser, loadRoute]);

    if (!currentUser || !config) return null;

    return (
        <div className={classes.outerContainer}>
            <ChooseInstitutionPagePresentation
                config={config}
                onApplyClick={handleApplyClick}
                loadingInstitutionId={loadingInstitutionId}
            />
        </div>
    );
}

export function ChooseInstitutionPagePresentation({
    config,
    onApplyClick,
    loadingInstitutionId,
}: ChooseInstitutionPagePresentationProps) {
    const { t } = useTranslation('back_royal');
    return (
        <div data-testid="choose-institution-page" className={classes.presentationContainer}>
            <h2 data-testid="page-heading" className={classes.pageHeading}>
                {t('choose_institution.choose_institution.choose_your_institution')}
            </h2>
            <div data-testid="institutions-container" className={classes.institutionsContainer}>
                {[InstitutionId.valar, InstitutionId.quantic].map(institutionId => (
                    <InstitutionItem
                        key={institutionId}
                        config={config[institutionId]!}
                        onApplyClick={onApplyClick}
                        loadingInstitutionId={loadingInstitutionId}
                    />
                ))}
            </div>
        </div>
    );
}
